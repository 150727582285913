import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useRef, useEffect, useState } from "react"
import "mapbox-gl/dist/mapbox-gl.css"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

import mapboxgl from "!mapbox-gl"
mapboxgl.accessToken =
  "pk.eyJ1Ijoic3R1ZGlvbXVsdGlwbHkiLCJhIjoiY2x4Mm04aGoxMGxlbzJxczl4YW96NHlhMyJ9.y7jUpkVndK9nmmJbO8IzXw"

import shoppingPin from "assets/images/rainfora/surrounding/shopping_pin.png"
import eduPin from "assets/images/rainfora/surrounding/edu_pin.png"
import parkPin from "assets/images/rainfora/surrounding/park_pin.png"
import medicalPin from "assets/images/rainfora/surrounding/medical_pin.png"
import transitPin from "assets/images/rainfora/surrounding/transit_pin.png"
import sensoriaPin from "assets/images/sensoria/surrounding/sensoria_landmark.png"

const Surrounding = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.leftSideBarType == "default") setLeftSideBarSize(200)
    else setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)

  const [def, setdef] = useState(0)
  const [dayNight, setDayNight] = useState(false)

  const mapContainer = useRef(null)
  const map = useRef(null) 
  const [lng, setLng] = useState(101.77024791284047)//101.77044124181256, 3.192052311952062
  const [lat, setLat] = useState(3.1944629280114074)
  const [zoom, setZoom] = useState(13.5)

  const [optionShow, setOptionShow] = useState(false)
  
  const [shoppingShow, setShoppingShow] = useState(true)
  const shoppingGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.75093759189822, 3.1773889325306004],
          },
          properties: {
            title: "Aeon Mall (2 KM)", // wangsa maju
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.77044124181256, 3.192052311952062],
          },
          properties: {
            title: "Giant Supermarket (1.5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.74268597614977, 3.1972892912128055],
          },
          properties: {
            title: "Wangsa Walk Mall (4.5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.75211582027148, 3.157863822269227],
          },
          properties: {
            title: "Ampang Point (5.5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.7494095552011, 3.211141000617434],
          },
          properties: {
            title: "Melawati Mall (4.5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.73055162690986, 3.2251208757986505],
          },
          properties: {
            title: "KL East Mall (7 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.71239034785725, 3.1584442678856908],
          },
          properties: {
            title: "KLCC (10 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.71515104734807, 3.148595442611345],
          },
          properties: {
            title: "Pavilion KL (11 KM)",
          },
        },
      ],
    },
  }

  const shoppingLayer = {
    id: "shoppingPoints",
    type: "symbol",
    source: "shoppingPoints",
    layout: {
      "icon-image": "shopping-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.5],
      "text-anchor": "top",
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const [eduShow, setEduShow] = useState(true)
  const eduGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.75244281711635, 3.1958545005738603],
          },
          properties: {
            title: "Fairview Intl. School (6 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.74208556423332, 3.1788754310325342],
          },
          properties: {
            title: "Brighton Intl. School (5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.73941632266569, 3.154611586147433],
          },
          properties: {
            title: "ISKL (8 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.73124274540758, 3.1600640848486043],
          },
          properties: {
            title: "Sayfol International School (8 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.72793575705091, 3.196857763883392],
          },
          properties: {
            title: "Sri Utama Schools (6.5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.72703989383017, 3.2154587237369303],
          },
          properties: {
            title: "TARUC (6 KM)",
          },
        },
      ],
    },
  }

  const eduLayer = {
    id: "eduPoints",
    type: "symbol",
    source: "eduPoints",
    layout: {
      "icon-image": "edu-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.5],
      "text-anchor": "top",
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const [medicalShow, setMedicalShow] = useState(true)
  const medicalGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.75534992132293, 3.1611415805145753],
          },
          properties: {
            title: "KPJ Ampang (5 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.74163767966478, 3.161551278821871],
          },
          properties: {
            title: "Gleneagles Hospital (6 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.72148266907233, 3.2024502576529024],
          },
          properties: {
            title: "Columbia Asia Hospital (8 KM)", // Setapak
          },
        },
      ],
    },
  }

  const medicalLayer = {
    id: "medicalPoints",
    type: "symbol",
    source: "medicalPoints",
    layout: {
      "icon-image": "medical-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.5],
      "text-anchor": "top",
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const [recreationalShow, setRecreationalShow] = useState(true)
  const recreationalGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.76036374856291, 3.210477340741354],
          },
          properties: {
            title: "Zoo Negara (3 KM)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.76742393502256, 3.2435087561085116],
          },
          properties: {
            title: "Klang Gates Quartz Ridge (6 KM)",
          },
        },
      ],
    },
  }

  const recreationalLayer = {
    id: "recreationalPoints",
    type: "symbol",
    source: "recreationalPoints",
    layout: {
      "icon-image": "recreational-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.5],
      "text-anchor": "top",
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const [transitShow, setTransitShow] = useState(true)
  const transitGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.69201956867069, 3.0586685473548743],
          },
          properties: {
            title: "LRT Bukit Jalil",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.68713239603329, 3.061818702877739],
          },
          properties: {
            title: "LRT Sri Petaling",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.67068726047019, 3.0623366114629995],
          },
          properties: {
            title: "LRT Awan Besar",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.66242498783076, 3.062361276555932],
          },
          properties: {
            title: "LRT Muhibbah",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.65664306501054, 3.05504024897043],
          },
          properties: {
            title: "LRT Alam Sutera",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.64424146485668, 3.0506688331135985],
          },
          properties: {
            title: "LRT Kinrara Bk5 (400 M)",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.62116430082871, 3.049207372024197],
          },
          properties: {
            title: "LRT IOI Puchong Jaya",
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.61611652967864, 3.0334899559174215],
          },
          properties: {
            title: "LRT Pusat Bandar Puchong",
          },
        },
      ],
    },
  }

  const transitLayer = {
    id: "transitPoints",
    type: "symbol",
    source: "transitPoints",
    layout: {
      "icon-image": "transit-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.5],
      "text-anchor": "top",
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const rainforaGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [101.76756830547322, 3.1929175526085136],
          },
          properties: {
            title: "",
          },
        },
      ],
    },
  }

  const rainforaLayer = {
    id: "rainforaPoints",
    type: "symbol",
    source: "rainforaPoints",
    layout: {
      "icon-image": "rainfora-marker",
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 0],
      "text-anchor": "top",
      "icon-anchor": "top",
      "icon-offset": [70,-15]
    },
    paint: {
      "text-color": "#FFFFFF",
    },
  }

  const infrastructureFilter = [
    {
      title: "Shopping Malls",
      showOption: shoppingShow,
      layerPoints: "shoppingPoints",
      layer: shoppingLayer,
      toggleOption: () => {
        setShoppingShow(!shoppingShow)
      },
      buttonType: "btn-outline-info bg-white"
    },
    {
      title: "Medical",
      showOption: medicalShow,
      layerPoints: "medicalPoints",
      layer: medicalLayer,
      toggleOption: () => {
        setMedicalShow(!medicalShow)
      },
      buttonType: "btn-outline-danger bg-white"
    },
    {
      title: "Education",
      showOption: eduShow,
      layerPoints: "eduPoints",
      layer: eduLayer,
      toggleOption: () => {
        setEduShow(!eduShow)
      },
      buttonType: "btn-outline-warning bg-white"
    },
    {
      title: "Recreational Attractions",
      showOption: recreationalShow,
      layerPoints: "recreationalPoints",
      layer: recreationalLayer,
      toggleOption: () => {
        setRecreationalShow(!recreationalShow)
      },
      buttonType: "btn-outline-success bg-white"
    },
  ]

  const transportFilter = [
    {
      title: "LRT",
      showOption: transitShow,
      layerPoints: "transitPoints",
      layer: transitLayer,
      toggleOption: () => {
        setTransitShow(!transitShow)
      },
    }
  ]

  useEffect(() => {
    console.log("")
    if (map.current) return // initialize map only once
    const sw = new mapboxgl.LngLat(101.60239034785725, 3.058595442611345)
    // const sw = new mapboxgl.LngLat(101.71239034785725, 3.148595442611345)
    const ne = new mapboxgl.LngLat(101.88044124181256, 3.3535087561085116)
    // const ne = new mapboxgl.LngLat(101.70061210880066, 3.072852361347084)
    const llb = new mapboxgl.LngLatBounds(sw, ne)
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/studiomultiply/cm1lpqhj000j601r282p321dg",
      center: [lng, lat],
      zoom: zoom,
      crossSourceCollisions: false,
      maxZoom: 15,
      // bearing: 270,
      minZoom: 10,
      // dragPan: false,
      maxBounds: llb,
    })

    // map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right")

    map.current.on("load", () => {
      // Add an image to use as a custom marker
      map.current.loadImage(sensoriaPin, (error, image) => {
        if (error) throw error
        map.current.addImage("rainfora-marker", image)
        map.current.addSource("rainforaPoints", rainforaGeojson)
        map.current.addLayer(rainforaLayer)
      })

      map.current.loadImage(shoppingPin, (error, image) => {
        if (error) throw error
        map.current.addImage("shopping-marker", image)
        map.current.addSource("shoppingPoints", shoppingGeojson)
        map.current.addLayer(shoppingLayer)
      })

      map.current.loadImage(eduPin, (error, image) => {
        if (error) throw error
        map.current.addImage("edu-marker", image)
        map.current.addSource("eduPoints", eduGeojson)
        map.current.addLayer(eduLayer)
      })

      map.current.loadImage(medicalPin, (error, image) => {
        if (error) throw error
        map.current.addImage("medical-marker", image)
        map.current.addSource("medicalPoints", medicalGeojson)
        map.current.addLayer(medicalLayer)
      })

      map.current.loadImage(parkPin, (error, image) => {
        if (error) throw error
        map.current.addImage("recreational-marker", image)
        map.current.addSource("recreationalPoints", recreationalGeojson)
        map.current.addLayer(recreationalLayer)
      })

      map.current.flyTo({ center: [lng, lat], zoom: zoom })
      // map.current.loadImage(transitPin, (error, image) => {
      //   if (error) throw error
      //   map.current.addImage("transit-marker", image)
      //   map.current.addSource("transitPoints", transitGeojson)
      //   map.current.addLayer(transitLayer)
      // })
    })
  })



  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{ backgroundImage: "radial-gradient(#164A3D, #01221F)" }}
      >
        {/* <div className="container-fluid"> */}
        {/* {pageData &&
          pageData.map((object, index) => componentHandler(object, index))} */}
        {/* <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"> */}
        <Row className="p-0">
          <Col className="p-0">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{ height: "100vh" }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: 16,
            paddingLeft: 32,
            paddingRight: 32,
            // right: 32,
            backgroundImage: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
          }}
          className="align-items-center"
        >
          <Col className="col-auto">
            <span className={"text-primary font-size-56 font-weight-bold "}>
              {"LOCATION"}
            </span>
          </Col>
        </Row>
        <Row
          style={{
            position: "fixed",
            bottom: 32,
            right: 32,
          }}
        >
          <Col>
            <span className="font-size-18" style={{ color: "#ff6047" }}>
              <i className="ph-fill ph-circle"></i>
            </span>{" "}
            <span className="text-white font-size-18">MRR2</span>
            <br />
            <span className="font-size-18" style={{ color: "#5cd3ff" }}>
              <i className="ph-fill ph-circle"></i>
            </span>{" "}
            <span className="text-white font-size-18">DUKE</span>
            <br />
            <span className="font-size-18" style={{ color: "#758cff" }}>
              <i className="ph-fill ph-circle"></i>
            </span>{" "}
            <span className="text-white font-size-18">SUKE</span>
            <br />
            <span className="font-size-18" style={{ color: "#fdfa3f" }}>
              <i className="ph-fill ph-circle"></i>
            </span>{" "}
            <span className="text-white font-size-18">AKLEH</span>
          </Col>
        </Row>
        <Card
          style={{
            backgroundColor: "#2F3D57CC",
            width: "218px",
            backdropFilter: "blur(20px)",
            borderRadius: "20px",
            position: "fixed",
            bottom: 0,
            left: 32 + leftSideBarSize,
          }}
          className="px-4 py-0 mb-3"
        >
          <Row>
            <Col>
              <Row className="my-2">
                <button
                  className={
                    "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/surrounding")
                  }}
                >
                  <span>{"Surroundings"}</span>
                </button>
              </Row>
              <Row className="my-2">
                <button
                  className={
                    "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/map")
                  }}
                >
                  <span>{"Map"}</span>
                </button>
              </Row>
              <Row className="my-2">
                <button
                  className={
                    "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/facade")
                  }}
                >
                  <span>{"Facade"}</span>
                </button>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            backgroundColor: "#2F3D57CC",
            width: "218px",
            backdropFilter: "blur(20px)",
            borderRadius: "16px",
            position: "fixed",
            bottom: 32 + 150,
            left: 32 + leftSideBarSize,
          }}
          className="px-4 py-0"
        >
          <Row>
            <Col className="col-auto">
              {infrastructureFilter.map((item, index) => (
                <Row className="my-2">
                  <button
                    className={
                      item.showOption
                        ? "btn btn-rounded font-weight-normal font-size-16 px-3 " +
                          item.buttonType
                        : "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-16 px-3"
                    }
                    style={{
                      // borderRadius: "25px",
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (item.showOption) {
                        if (map.current.getLayer(item.layerPoints))
                          map.current.removeLayer(item.layerPoints)
                      } else {
                        map.current.addLayer(item.layer)
                      }

                      item.toggleOption()
                    }}
                  >
                    <span>{item.title}</span>
                  </button>
                </Row>
              ))}
              {/* {transportFilter.map((item, index) => (
                <Row className="my-2">
                  <button
                    className={
                      item.showOption
                        ? "btn btn-outline-primary bg-white btn-rounded font-weight-normal font-size-16 px-3"
                        : "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-16 px-3"
                    }
                    style={{
                      // borderRadius: "25px",
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (item.showOption) {
                        if (map.current.getLayer(item.layerPoints))
                          map.current.removeLayer(item.layerPoints)
                      } else {
                        map.current.addLayer(item.layer)
                      }

                      item.toggleOption()
                    }}
                  >
                    <span>{item.title}</span>
                  </button>
                </Row>
              ))} */}
            </Col>
          </Row>
        </Card>
        <button
          className={
            "btn text-color-919695 btn-primary font-weight-normal font-size-30 border-none"
          }
          style={{
            backgroundColor: "#2F3D57CC",
            backdropFilter: "blur(20px)",
            height: "60px",
            width: "60px",
            position: "fixed",
            bottom: 16,
            left: 32 + leftSideBarSize + 218 + 16,
            borderRadius: "30px",
          }}
          onClick={() => {
            map.current.flyTo({ center: [lng, lat], zoom: zoom })
          }}
        >
          <i className="ph ph-gps"></i>
        </button>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(connect(mapStateToProps, { showJoinNowAction })(Surrounding))

Surrounding.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
