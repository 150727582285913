import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  UncontrolledCarousel,
  CarouselIndicators,
  Carousel,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

import project1 from "assets/images/rainfora/info/0trinity_wellnessa.webp"
import project2 from "assets/images/rainfora/info/1trinity_pentamont@mont_Kiara.webp"
import project3 from "assets/images/rainfora/info/2trinity_lemanja@kepong.webp"
import project4 from "assets/images/rainfora/info/3trinity_aquata@KL_south.webp"
import project5 from "assets/images/rainfora/info/4zeva@equine_south.webp"
import project6 from "assets/images/rainfora/info/5the_z_residence@bukit_jalil.webp"
import project7 from "assets/images/rainfora/info/6the_zest@kinrara_9.webp"
import project8 from "assets/images/rainfora/info/7latitude@USJ_19.webp"
import project9 from "assets/images/rainfora/info/8_19_residency@bdr_bukit_puchong.webp"
import project10 from "assets/images/rainfora/info/9heron_residency@bandar_bukit_puchong.webp"

import bgImage from "assets/images/sensoria/allbg.png"

const Info = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.leftSideBarType == "default") setLeftSideBarSize(200)
    else setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)

  const pastProjects = [
    {
      altText: "Trinity Wellnessa",
      caption: "",
      key: 1,
      src: project1,
    },
    {
      altText: "Trinity Pentamont",
      caption: "",
      key: 2,
      src: project2,
    },
    {
      altText: "Trinity Lemanja",
      caption: "",
      key: 3,
      src: project3,
    },
    {
      altText: "Trinity Aquata",
      caption: "",
      key: 4,
      src: project4,
    },
    {
      altText: "Zeva",
      caption: "",
      key: 5,
      src: project5,
    },
    {
      altText: "The Z Residence",
      caption: "",
      key: 6,
      src: project6,
    },
    {
      altText: "The Zest",
      caption: "",
      key: 7,
      src: project7,
    },
    {
      altText: "Latitude",
      caption: "",
      key: 8,
      src: project8,
    },
    {
      altText: "19 Residency",
      caption: "",
      key: 9,
      src: project9,
    },
    {
      altText: "Heron Residency",
      caption: "",
      key: 10,
      src: project10,
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === pastProjects.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? pastProjects.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = pastProjects.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} style={{objectFit:"contain", maxWidth:"55vw"}} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        // className="page-content p-0"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          maxHeight: "100vh",
          minHeight: "100vh",
        }}
      >
        {/* <div className="container-fluid"> */}
        {/* {pageData &&
          pageData.map((object, index) => componentHandler(object, index))} */}
        {/* <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"> */}
        {pastProjects.map((item, index) => (
          <img
            src={item.src}
            style={{ display: "none" }}
            key={"preload-pass-projects-" + index}
          />
        ))}
        <Row className="mt-0 ms-3">
          <Col>
            <span className="text-primary font-size-56 font-weight-bold  p-3">
              {"PAST PROJECTS"}
            </span>
          </Col>
        </Row>
        <Row className="p-0" style={{ marginLeft: 32 + leftSideBarSize + 218 }}>
          <Col className="p-0">
            <Card className="p-3 mb-0 bg-transparent">
              <CardBody className="p-3 align-self-center">
                {/* <UncontrolledCarousel items={pastProjects} /> */}
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  slide={false}
                  fade={true}
                  interval={3000}
                >
                  {/* <CarouselIndicators
                    items={pastProjects}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  /> */}
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card
          style={{
            backgroundColor: "#2F3D57CC",
            width: "218px",
            backdropFilter: "blur(20px)",
            borderRadius: "20px",
            position: "fixed",
            bottom: 0,
            left: 32 + leftSideBarSize,
          }}
          className="px-4 py-0 mb-3"
        >
          <Row>
            <Col>
              {pastProjects.map((item, index) => (
                <Row className="my-2">
                  <button
                    key={"past-project-" + index}
                    className={
                      index == activeIndex
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      goToIndex(index)
                    }}
                  >
                    <span>{item.altText}</span>
                  </button>
                </Row>
              ))}
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftSideBarType } = state.Layout
  return { leftSideBarType }
}

export default withRouter(connect(mapStateToProps, { showJoinNowAction })(Info))

Info.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftSideBarType: PropTypes.any,
}
