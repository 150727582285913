const UnitPoint = [
  [
    {
      type: "A2(M)",
      block: "B",
      level: "01",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "01",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "01",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "01",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "02",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "02",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "02",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "02",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "03",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "03",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "03",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "03",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "03A",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "03A",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "03A",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "03A",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "05",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "05",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "05",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "05",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "05",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "05",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "05",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B4",
      block: "B",
      level: "05",
      unit: "08",
      sqft: "1,697 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A3",
      block: "B",
      level: "05",
      unit: "09",
      sqft: "1,290 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A3(M)",
      block: "B",
      level: "05",
      unit: "10",
      sqft: "1,290 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B3(M)",
      block: "B",
      level: "05",
      unit: "11",
      sqft: "1,705 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B3",
      block: "A2",
      level: "05",
      unit: "01",
      sqft: "1,705 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B3(M)",
      block: "A2",
      level: "05",
      unit: "02",
      sqft: "1,705 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A3",
      block: "A2",
      level: "05",
      unit: "03",
      sqft: "1,290 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B3",
      block: "A2",
      level: "05",
      unit: "03A",
      sqft: "1,705 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "05",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "05",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "05",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "05",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "05",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "B2",
      block: "A2",
      level: "05A",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "05A",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "05A",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "05A",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "05A",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "05A",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "05A",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "05A",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "05A",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "06",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "06",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "06",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "06",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "06",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "06",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "06",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "06",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "06",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "06",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "06",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "06",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "06",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "06",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "06",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "06",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "06",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "06",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "06",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "06",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "06",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "06",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "06",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "06",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "06",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "06",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "06",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "06",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "06",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "06",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "07",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "07",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "07",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "07",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "07",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "07",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "07",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "07",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "07",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "07",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "07",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "07",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "07",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "07",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "07",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "07",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "07",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "07",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "07",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "07",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "07",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "07",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "07",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "07",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "07",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "07",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "07",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "07",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "07",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "07",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "08",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "08",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "08",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "08",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "08",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "08",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "08",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "08",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "08",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "08",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "08",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "08",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "08",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "08",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "08",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "08",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "08",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "08",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "08",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "08",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "08",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "08",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "08",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "08",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "08",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "08",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "08",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "08",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "08",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "08",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "09",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "09",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "09",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "09",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "09",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "09",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "09",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "09",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "09",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "09",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "09",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "09",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "09",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "09",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "09",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "09",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "09",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "09",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "09",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "09",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "09",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "09",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "09",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "09",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "09",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "09",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "09",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "09",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "09",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "09",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "10",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "10",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "10",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "10",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "10",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "10",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "10",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "10",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "10",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "10",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "10",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "10",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "10",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "10",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "10",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "10",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "10",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "10",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "10",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "10",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "10",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "10",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "10",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "10",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "10",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "10",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "10",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "10",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "10",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "10",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "11",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "11",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "11",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "11",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "11",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "11",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "11",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "11",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "11",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "11",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "11",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "11",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "11",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "11",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "11",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "11",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "11",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "11",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "11",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "11",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "11",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "11",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "11",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "11",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "11",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "11",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "11",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "11",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "11",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "11",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "12",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "12",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "12",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "12",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "12",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "12",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "12",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "12",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "12",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "12",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "12",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "12",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "12",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "12",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "12",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "12",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "12",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "12",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "12",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "12",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "12",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "12",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "12",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "12",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "12",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "12",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "12",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "12",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "12",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "12",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "13",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "13",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "13",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "13",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "13",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "13",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "13",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "13",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "13",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "13",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "13",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "13",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "13",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "13",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "13",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "13",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "13",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "13",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "13",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "13",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "13",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "13",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "13",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "13",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "13",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "13",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "13",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "13A",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13A",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "13A",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13A",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "13A",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "13A",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "13A",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "13A",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "13A",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "13A",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "13A",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "13A",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "13A",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "13A",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "13A",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "13A",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "13A",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "13A",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "13A",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "13A",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "13A",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "13A",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "13A",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "13A",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "13A",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "13A",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "13A",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "13A",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "13A",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "13A",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "15",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "15",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "15",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "15",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "15",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "15",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "15",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "15",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "15",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "15",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "15",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "15",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "15",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "15",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "15",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "15",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "15",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "15",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "15",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "15",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "15",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "15",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "15",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "15",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "15",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "15",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "15",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "15",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "15",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "15",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "16",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "16",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "16",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "16",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "16",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "16",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "16",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "16",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "16",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "16",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "16",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "16",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "16",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "16",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "16",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "16",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "16",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "16",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "16",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "16",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "16",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "16",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "16",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "16",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "16",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "16",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "16",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "16",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "16",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "16",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "17",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "17",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "17",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "17",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "17",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "17",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "17",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "17",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "17",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "17",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "17",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "17",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "17",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "17",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "17",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "17",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "17",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "17",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "17",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "17",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "17",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "17",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "17",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "17",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "17",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "17",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "17",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "17",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "17",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "17",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "18",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "18",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "18",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "18",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "18",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "18",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "18",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "18",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "18",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "18",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "18",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "18",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "18",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "18",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "18",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "18",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "18",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "18",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "18",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "18",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "18",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "18",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "18",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "18",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "18",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "18",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "18",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "18",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "18",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "18",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "19",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "19",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "19",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "19",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "19",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "19",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "19",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "19",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "19",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "19",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "19",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "19",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "19",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "19",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "19",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "19",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "19",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "19",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "19",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "19",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "19",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "19",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "19",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "19",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "19",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "19",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "19",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "19",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "19",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "19",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "20",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "20",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "20",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "20",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "20",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "20",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "20",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "20",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "20",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "20",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "20",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "20",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "20",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "20",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "20",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "20",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "20",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "20",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "20",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "20",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "20",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "20",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "20",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "20",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "20",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "20",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "20",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "20",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "20",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "20",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "21",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "21",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "21",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "21",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "21",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "21",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "21",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "21",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "21",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "21",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "21",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "21",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "21",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "21",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "21",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "21",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "21",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "21",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "21",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "21",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "21",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "21",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "21",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "21",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "21",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "21",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "21",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "21",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "21",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "21",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "22",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "22",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "22",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "22",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "22",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "22",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "22",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "22",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "22",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "22",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "22",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "22",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "22",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "22",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "22",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "22",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "22",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "22",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "22",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "22",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "22",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "22",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "22",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "22",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "22",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "22",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "22",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "22",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "22",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "22",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "23",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "23",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "23",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "23",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "23",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "23",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "23",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "23",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "23",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "23",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "23",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "23",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "23",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "23",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "23",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "23",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "23",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "23",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "23",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "23",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "23",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "23",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "23",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "23",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "23",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "23",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "23",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "23A",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23A",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "23A",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23A",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "23A",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "23A",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "23A",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "23A",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "23A",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "23A",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "23A",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "23A",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "23A",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "23A",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "23A",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "23A",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "23A",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "23A",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "23A",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "23A",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "23A",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "23A",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "23A",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "23A",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "23A",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "23A",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "23A",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "23A",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "23A",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "23A",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "25",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "25",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "25",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "25",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "25",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "25",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "25",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "25",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "25",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "25",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "25",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "25",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "25",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "25",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "25",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "25",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "25",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "25",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "25",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "25",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "25",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "25",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "25",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "25",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "25",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "25",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "25",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "25",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "25",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "25",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "26",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "26",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "26",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "26",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "26",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "26",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "26",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "26",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "26",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "26",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "26",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "26",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "26",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "26",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "26",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "26",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "26",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "26",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "26",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "26",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "26",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "26",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "26",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "26",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "26",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "26",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "26",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "26",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "26",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "26",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "27",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "27",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "27",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "27",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "27",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "27",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "27",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "27",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "27",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "27",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "27",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "B2",
      block: "A2",
      level: "27",
      unit: "01",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2850,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B2(M)",
      block: "A2",
      level: "27",
      unit: "02",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 2565,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "A1",
      block: "A2",
      level: "27",
      unit: "03",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 656,
      left: 2225,
      width: 183,
      height: 204,
      face: "west",
    },
    {
      type: "B2",
      block: "A2",
      level: "27",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 656,
      left: 1990,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "C3(M)",
      block: "A2",
      level: "27",
      unit: "05",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2944,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2",
      block: "A2",
      level: "27",
      unit: "06",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2692,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A2",
      level: "27",
      unit: "07",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 439,
      left: 2456,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C2(M)",
      block: "A2",
      level: "27",
      unit: "08",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 2197,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C3",
      block: "A2",
      level: "27",
      unit: "09",
      sqft: "1,396 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 439,
      left: 1949,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1(M)",
      block: "A1",
      level: "27",
      unit: "01",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "C1",
      block: "A1",
      level: "27",
      unit: "02",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "27",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "27",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "27",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "27",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "27",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "27",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "27",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "27",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "28",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "28",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "28",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "28",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "28",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "28",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "28",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "28",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "28",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "28",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "28",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "D2",
      block: "A1",
      level: "28",
      unit: "01",
      sqft: "2591 Sq.ft",
      bedroom: "5+1",
      bathroom: "6",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "D1",
      block: "A1",
      level: "28",
      unit: "02",
      sqft: "2,591 Sq.ft",
      bedroom: "5+1",
      bathroom: "6",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1(M)",
      block: "A1",
      level: "28",
      unit: "03",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1243,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "B1",
      block: "A1",
      level: "28",
      unit: "03A",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 1011,
      width: 235,
      height: 204,
      face: "west",
    },
    {
      type: "B5",
      block: "A1",
      level: "28",
      unit: "05",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 856,
      left: 778,
      width: 255,
      height: 204,
      face: "west",
    },
    {
      type: "C4",
      block: "A1",
      level: "28",
      unit: "06",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 555,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "C1",
      block: "A1",
      level: "28",
      unit: "07",
      sqft: "1,450 Sq.ft",
      bedroom: "4+1",
      bathroom: "4",
      top: 628,
      left: 806,
      width: 255,
      height: 204,
      face: "east",
    },
    {
      type: "A1",
      block: "A1",
      level: "28",
      unit: "08",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 628,
      left: 1258,
      width: 195,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "28",
      unit: "09",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1453,
      width: 235,
      height: 204,
      face: "east",
    },
    {
      type: "B2",
      block: "A1",
      level: "28",
      unit: "10",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 628,
      left: 1688,
      width: 235,
      height: 204,
      face: "east",
    },
  ],
  [
    {
      type: "A2(M)",
      block: "B",
      level: "29",
      unit: "01",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 720,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "29",
      unit: "02",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 902,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "29",
      unit: "03",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1088,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "29",
      unit: "03A",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1272,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2(M)",
      block: "B",
      level: "29",
      unit: "05",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1439,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A2",
      block: "B",
      level: "29",
      unit: "06",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1625,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "A4(M)",
      block: "B",
      level: "29",
      unit: "07",
      sqft: "1,013 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1813,
      left: 3331,
      width: 183,
      height: 189,
      face: "south",
    },
    {
      type: "B6",
      block: "B",
      level: "29",
      unit: "08",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1813,
      left: 3100,
      width: 194,
      height: 233,
      face: "north",
    },
    {
      type: "A1",
      block: "B",
      level: "29",
      unit: "09",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1635,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "A1(M)",
      block: "B",
      level: "29",
      unit: "10",
      sqft: "1,008 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 1450,
      left: 3100,
      width: 194,
      height: 189,
      face: "north",
    },
    {
      type: "B2(M)",
      block: "B",
      level: "29",
      unit: "11",
      sqft: "1,286 Sq.ft",
      bedroom: "4",
      bathroom: "3",
      top: 1018,
      left: 3120,
      width: 174,
      height: 226,
      face: "north",
    },
    {
      type: "D2U",
      block: "A1",
      level: "29",
      unit: "01",
      sqft: "2591 Sq.ft",
      bedroom: "5+1",
      bathroom: "6",
      top: 856,
      left: 1736,
      width: 275,
      height: 204,
      face: "west",
    },
    {
      type: "D1U",
      block: "A1",
      level: "29",
      unit: "02",
      sqft: "2,591 Sq.ft",
      bedroom: "5+1",
      bathroom: "6",
      top: 856,
      left: 1480,
      width: 275,
      height: 204,
      face: "west",
    },
  ],
]

export default UnitPoint