import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef, useLayoutEffect } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import level5BalconyDay from "assets/images/sensoria/unit/balcony_view/day_05.webp"
import level10BalconyDay from "assets/images/sensoria/unit/balcony_view/day_10.webp"
import level15BalconyDay from "assets/images/sensoria/unit/balcony_view/day_15.webp"
import level20BalconyDay from "assets/images/sensoria/unit/balcony_view/day_20.webp"
import level25BalconyDay from "assets/images/sensoria/unit/balcony_view/day_25.webp"
import level29BalconyDay from "assets/images/sensoria/unit/balcony_view/day_29.webp"
import level5BalconyNight from "assets/images/sensoria/unit/balcony_view/night_05.webp"
import level10BalconyNight from "assets/images/sensoria/unit/balcony_view/night_10.webp"
import level15BalconyNight from "assets/images/sensoria/unit/balcony_view/night_15.webp"
import level20BalconyNight from "assets/images/sensoria/unit/balcony_view/night_20.webp"
import level25BalconyNight from "assets/images/sensoria/unit/balcony_view/night_25.webp"
import level29BalconyNight from "assets/images/sensoria/unit/balcony_view/night_29.webp"

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"
import { CompassPlugin } from "@photo-sphere-viewer/compass-plugin"
import "@photo-sphere-viewer/compass-plugin/index.css"

import UnitPoint from "./unitPoint"

import iconTap from "assets/images/rainfora/unit/button-tap-icon.png"

import a1Plan from "assets/images/sensoria/unit/floor_plan/A1.png"
import a1mPlan from "assets/images/sensoria/unit/floor_plan/A1(m).png"
import a2Plan from "assets/images/sensoria/unit/floor_plan/A2.png"
import a2mPlan from "assets/images/sensoria/unit/floor_plan/A2(m).png"
import a3Plan from "assets/images/sensoria/unit/floor_plan/A3.png"
import a3mPlan from "assets/images/sensoria/unit/floor_plan/A3(m).png"
import a4mPlan from "assets/images/sensoria/unit/floor_plan/A4(m).png"
import b1Plan from "assets/images/sensoria/unit/floor_plan/B1.png"
import b1mPlan from "assets/images/sensoria/unit/floor_plan/B1(m).png"
import b2Plan from "assets/images/sensoria/unit/floor_plan/B2.png"
import b2mPlan from "assets/images/sensoria/unit/floor_plan/B2(m).png"
import b2aPlan from "assets/images/sensoria/unit/floor_plan/B2a.png"
import b2amPlan from "assets/images/sensoria/unit/floor_plan/B2a(m).png"
import b3Plan from "assets/images/sensoria/unit/floor_plan/B3.png"
import b3mPlan from "assets/images/sensoria/unit/floor_plan/B3(m).png"
import b4Plan from "assets/images/sensoria/unit/floor_plan/B4.png"
import b5Plan from "assets/images/sensoria/unit/floor_plan/B5.png"
import b6Plan from "assets/images/sensoria/unit/floor_plan/B6.png"
import c1Plan from "assets/images/sensoria/unit/floor_plan/C1.png"
import c1mPlan from "assets/images/sensoria/unit/floor_plan/C1(m).png"
import c2Plan from "assets/images/sensoria/unit/floor_plan/C2.png"
import c2aPlan from "assets/images/sensoria/unit/floor_plan/C2a.png"
import c3Plan from "assets/images/sensoria/unit/floor_plan/C3.png"
import c3mPlan from "assets/images/sensoria/unit/floor_plan/C3(m).png"
import c4Plan from "assets/images/sensoria/unit/floor_plan/C4.png"
import d1lPlan from "assets/images/sensoria/unit/floor_plan/D1 Lower.png"
import d1uPlan from "assets/images/sensoria/unit/floor_plan/D1(upper).png"
import d2lPlan from "assets/images/sensoria/unit/floor_plan/D2 (lower).png"
import d2uPlan from "assets/images/sensoria/unit/floor_plan/D2(upper).png"

import compassNorth from "assets/images/rainfora/north.png"
import bgImage from "assets/images/sensoria/allbg.png"

const imageUnit = require.context("assets/images/sensoria/unit/floor", true)
const imageUnitList = imageUnit.keys().map(image => imageUnit(image))
const image360List = [level5BalconyDay, level5BalconyNight, level10BalconyDay, level10BalconyNight, level15BalconyDay, level15BalconyNight, level20BalconyDay, level20BalconyNight, level25BalconyDay, level25BalconyNight, level29BalconyDay, level29BalconyNight];

const Unit = props => {
  const history = useHistory()

  const imagePlan = [
    a1Plan, a1mPlan, a2Plan, a2mPlan, a3Plan, a3mPlan, a4mPlan, b1Plan, b1mPlan, b2Plan, b2mPlan, b2aPlan, b2amPlan, b3Plan, b3mPlan, b4Plan, b5Plan, b6Plan, c1Plan, c1mPlan, c2Plan, c2aPlan, c3Plan, c3mPlan, c4Plan, d1lPlan, d1uPlan, d2lPlan, d2uPlan
  ]

  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(0)
  const [step, setStep] = useState(0)
  const [dayNight, setDayNight] = useState(true)

  const floorLabel = {
    0: "01",
    1: "02",
    2: "03",
    3: "03A",
    4: "05",
    5: "05A",
    6: "06",
    7: "07",
    8: "08",
    9: "09",
    10:"10",
    11: "11",
    12: "12",
    13: "13",
    14: "13A",
    15: "15",
    16: "16",
    17: "17",
    18: "18",
    19: "19",
    20: "20",
    21: "21",
    22: "22",
    23: "23A",
    24: "24",
    25: "25",
    26: "26",
    27: "27",
    28: "28",
    29: "29",
  }

  const full360Step = ["29","25","20","15","10","05"]
  const full360ViewContent = {
    "05": { day: level5BalconyDay, night: level5BalconyNight },
    "10": { day: level10BalconyDay, night: level10BalconyNight },
    "15": { day: level15BalconyDay, night: level15BalconyNight },
    "20": { day: level20BalconyDay, night: level20BalconyNight },
    "25": { day: level25BalconyDay, night: level25BalconyNight },
    "29": { day: level29BalconyDay, night: level29BalconyNight },
  }

  const balconyLevel = {
    "01": "5",
    "02": "5",
    "03": "5",
    "04": "5",
    "05": "5",
    "05A": "5",
    "06": "5",
    "07": "5",
    "08": "5",
    "09": "5",
    "10": "10",
    "11": "10",
    "12": "10",
    "13": "10",
    "14": "15",
    "15": "15",
    "16": "15",
    "17": "15",
    "18": "15",
    "19": "20",
    "20": "20",
    "21": "20",
    "22": "20",
    "23": "20",
    "24": "25",
    "25": "25",
    "26": "25",
    "27": "25",
    "28": "25",
    "29": "29",
  }

  // const balconyViewContent = {
  //   "09": { day: level9BalconyDay, night: level9BalconyNight },
  //   "10": { day: level9BalconyDay, night: level9BalconyNight },
  //   "11": { day: level9BalconyDay, night: level9BalconyNight },
  //   "12": { day: level9BalconyDay, night: level9BalconyNight },
  //   "13": { day: level9BalconyDay, night: level9BalconyNight },
  //   "14": { day: level15BalconyDay, night: level15BalconyNight },
  //   "15": { day: level15BalconyDay, night: level15BalconyNight },
  //   "16": { day: level15BalconyDay, night: level15BalconyNight },
  //   "17": { day: level15BalconyDay, night: level15BalconyNight },
  //   "18": { day: level15BalconyDay, night: level15BalconyNight },
  //   "19": { day: level20BalconyDay, night: level20BalconyNight },
  //   "20": { day: level20BalconyDay, night: level20BalconyNight },
  //   "21": { day: level20BalconyDay, night: level20BalconyNight },
  //   "22": { day: level20BalconyDay, night: level20BalconyNight },
  //   "23": { day: level20BalconyDay, night: level20BalconyNight },
  //   "24": { day: level25BalconyDay, night: level25BalconyNight },
  //   "25": { day: level25BalconyDay, night: level25BalconyNight },
  //   "26": { day: level25BalconyDay, night: level25BalconyNight },
  //   "27": { day: level25BalconyDay, night: level25BalconyNight },
  //   "28": { day: level25BalconyDay, night: level25BalconyNight },
  //   "29": { day: level30BalconyDay, night: level30BalconyNight },
  //   "30": { day: level30BalconyDay, night: level30BalconyNight },
  //   "31": { day: level30BalconyDay, night: level30BalconyNight },
  // }

  const [balconyViewVR, setbalconyViewVR] = useState(false)
  const [balconyViewVRContent, setbalconyViewVRContent] = useState(
    level5BalconyDay
  )
  const [balconyViewVRLevel, setbalconyViewVRLevel] = useState("09")
  const [balconyViewVRDirection, setbalconyViewVRDirection] = useState("South")

  const [full360ViewVR, setfull360ViewVR] = useState(false)
  const [full360ViewVRContent, setfull360ViewVRContent] = useState(
    level29BalconyDay
  )
  const [full360ViewVRLevel, setfull360ViewVRLevel] = useState("29")

  const [comparisonView, setComparisonView] = useState(false)

  const [currentCompareItem, setCurrentCompareItem] = useState(0)
  const [comparisonItem1, setComparisonItem1] = useState(null)
  const [comparisonItem2, setComparisonItem2] = useState(null)

  const floorDropDown = ["01", "02", "03", "03A", "05", "05A", "06", "07", "08", "09", "10", "11", "12", "13", "13A", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23A", "25", "26", "27", "28", "29", "30", "31"];
  const [selectedFloor1, setSelectedFloor1] = useState("01")
  const [selectedFloor2, setSelectedFloor2] = useState("01")
  const [dropdownOpenFloor1, setDropdownOpenFloor1] = useState(false)
  const [dropdownOpenFloor2, setDropdownOpenFloor2] = useState(false)
  const [unitDropdown1, setUnitDropdown1] = useState(UnitPoint[0])
  const [unitDropdown2, setUnitDropdown2] = useState(UnitPoint[0])
  const [dropdownOpenUnit1, setDropdownOpenUnit1] = useState(false)
  const [dropdownOpenUnit2, setDropdownOpenUnit2] = useState(false)

  const [floorWidth, setFloorWidth] = useState(0)
  const [floorHeight, setFloorHeight] = useState(0)
  const ref = useRef(null)
  const pSRef = useRef(null)
  const bvRef = useRef(null)

  const plugins = [
    [
      CompassPlugin,
      {
        size: "90px",
        position: 'top right'
        // hotspots: [
        //   { longitude: "0deg" },
        //   { longitude: "90deg" },
        //   { longitude: "180deg" },
        //   { longitude: "270deg" },
        // ],
      },
    ],
  ]

  useLayoutEffect(() => {
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 2109)
      setFloorWidth(width / 4000)
    }, 1000)
  }, [])

  useEffect(() => {    
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 2109)
      setFloorWidth(width / 4000)
    }, 1000)
  }, [leftSideBarSize])

  const getDirectionDegree = (direction) => {
    switch (direction) {
      case "East": return "90deg"
      case "West": return "270deg"
      case "North": return "0deg"
      case "South": return "180deg"
    }
  }

  const getVRfilename = (level, direction) => {
    setbalconyViewVRContent(
      "https://rainfora.studiomultiply.com/tv/balcony_view/" +
        "LEVEL_" +
        balconyLevel[level] +
        (dayNight ? "_DAY_" : "_NIGHT_") +
        direction.toUpperCase() +
        "/LEVEL_" +
        balconyLevel[level] +
        (dayNight ? "_DAY.html" : "_NIGHT.html")
    )
  }

  useEffect(() => {
    var iframeElement = document.getElementById("balconyViewIframe")
    
    getVRfilename(balconyViewVRLevel, balconyViewVRDirection)
    
    if (iframeElement && balconyViewVRContent && balconyViewVRContent != "") {
      document.getElementById("balconyViewIframe").src = balconyViewVRContent
    }
  }, [balconyViewVRContent, dayNight])

  const getFloorPlan = (type) => {
    switch (type) {
      case "A1":
        return a1Plan
        break
      case "A1(M)":
        return a1mPlan
        break
      case "A2":
        return a2Plan
        break
      case "A2(M)":
        return a2mPlan
        break
      case "A3":
        return a3Plan
        break
      case "A3(M)":
        return a3mPlan
        break
      case "A4(M)":
        return a4mPlan
        break
      case "B1":
        return b1Plan
        break
      case "B1(M)":
        return b1mPlan
        break
      case "B2":
        return b2Plan
        break
      case "B2(M)":
        return b2mPlan
        break
      case "B2a":
        return b2aPlan
        break
      case "B2a(M)":
        return b2amPlan
        break
      case "B3":
        return b3Plan
        break
      case "B3(M)":
        return b3mPlan
        break
      case "B4":
        return b4Plan
        break
      case "B5":
        return b5Plan
        break
      case "B6":
        return b6Plan
        break
      case "C1":
        return c1Plan
        break
      case "C1(M)":
        return c1mPlan
        break
      case "C2":
        return c2Plan
        break
      case "C2a":
        return c2aPlan
        break
      case "C3":
        return c3Plan
        break
      case "C3(M)":
        return c3mPlan
        break
      case "C4":
        return c4Plan
        break
      case "D1L":
        return d1lPlan
        break
      case "D1U":
        return d1uPlan
        break
      case "D2L":
        return d2lPlan
        break
      case "D2U":
        return d2uPlan
        break
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          height: "100vh",
        }}
      >
        {imageUnitList.map((item, index) => (
          <img
            src={imageUnitList[index]}
            style={{ display: "none" }}
            key={"preload-unit-" + index}
          />
        ))}

        {image360List.map((item, index) => (
          <img
            src={image360List[index]}
            style={{ display: "none" }}
            key={"preload-360-" + index}
          />
        ))}

        {imagePlan.map((item, index) => (
          <img
            src={imagePlan[index]}
            style={{ display: "none" }}
            key={"preload-plan-" + index}
          />
        ))}
        <Row className="">
          <Col className="">
            <Card
              className="p-0 mb-0"
              style={{
                // backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundColor: "rgba(52, 52, 52, 0.0)",
              }}
            >
              {!balconyViewVR && !comparisonView && !full360ViewVR && (
                <CardBody
                  className="p-0"
                  style={{
                    height: "100%",
                  }}
                >
                  <Row className="justify-content-center">
                    <Col
                      className="col-9"
                      // style={{ overflowY: "-moz-hidden-unscrollable" }}
                    >
                      <Row className="m-0">
                        <Col style={{ paddingTop: "8vh" }}>
                          <div style={{ position: "relative" }} ref={ref}>
                            {UnitPoint &&
                              UnitPoint[def].map((item, index) => (
                                <button
                                  key={"point" + index}
                                  className={
                                    "btn btn-primary font-weight-normal font-size-16 px-3 bg-transparent"
                                  }
                                  style={{
                                    position: "absolute",
                                    top: (item.top - def * 15) * floorHeight,
                                    left: item.left * floorWidth,
                                    borderWidth: 0,
                                    width: item.width * floorWidth,
                                    height: item.height * floorHeight,
                                  }}
                                  onClick={() => {
                                    if (currentCompareItem == 0) {
                                      setComparisonItem1(item)
                                      setSelectedFloor1(item.level)
                                      setCurrentCompareItem(1)
                                    } else {
                                      setComparisonItem2(item)
                                      setSelectedFloor2(item.level)
                                      setCurrentCompareItem(0)
                                    }
                                  }}
                                >
                                  <span>{""}</span>
                                </button>
                              ))}
                            <CardImg
                              src={imageUnitList[def]}
                              // className="p-5"
                              style={{
                                // height: "295px",
                                // height: "100vh",
                                // maxHeight: "80vh",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <Row
                            style={{
                              position: "absolute",
                              bottom: 100,
                              top: "30%",
                              // left: leftSideBarSize + 300,
                              left: 0,
                            }}
                          >
                            <Col>
                              <Slider
                                value={def}
                                orientation="vertical"
                                min={0}
                                max={29}
                                onChange={value => {
                                  setdef(value)
                                }}
                                tooltip={false}
                                // labels={floorLabel}
                                // handleLabel={floorLabel[def]}
                                // style={{
                                //   position: "absolute",
                                //   bottom: 100,
                                //   right: 10,
                                // }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          paddingTop: 16,
                          paddingLeft: 32,
                          paddingRight: 32,
                          zIndex: 1,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-between align-items-center"
                      >
                        <Col className="col-auto">
                          <span className="text-primary font-size-56 font-weight-bold me-4">
                            {"UNIT TYPE"}
                          </span>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          paddingTop: "5vh",
                          paddingLeft: 32,
                          paddingRight: 32,
                          zIndex: 1,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-center align-items-center"
                      >
                        <Col className="col-auto">
                          <span className="text-white font-size-40 font-weight-bold ">
                            {"Level " + floorLabel[def]}
                          </span>
                        </Col>
                      </Row>
                      <Card
                        style={{
                          backgroundColor: "#2F3D57CC",
                          width: "168px",
                          backdropFilter: "blur(20px)",
                          borderRadius: "20px",
                          position: "fixed",
                          bottom: 0,
                          left: 32 + leftSideBarSize,
                          zIndex: 10,
                        }}
                        className="px-4 py-0 mb-3"
                      >
                        <Row>
                          <Col>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {}}
                              >
                                <span>{"Overview"}</span>
                              </button>
                            </Row>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {
                                  setComparisonView(true)
                                }}
                              >
                                <span>{"Comparison"}</span>
                              </button>
                            </Row>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {
                                  setfull360ViewVR(true)
                                }}
                              >
                                <span>{"Full 360 View"}</span>
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Row
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          paddingTop: 16,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-end"
                      >
                        <Col className="col-auto">
                          <img
                            src={compassNorth}
                            style={{ width: 80, height: 80, rotate: "-135deg", position: "fixed", top: 16, right: 32 }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Row
                      style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        left: 32 + leftSideBarSize,
                        zIndex: 1,
                        // right: 32,
                        // backgroundImage:
                        //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                      }}
                      className="justify-content-center align-items-center"
                    >
                      <Col className="col-auto">
                        {comparisonItem1 ? (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#2F3D57CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="my-0 text-primary font-size-20 ">
                                    {"Type " +
                                      comparisonItem1.type +
                                      " (" +
                                      comparisonItem1.block +
                                      "-" +
                                      comparisonItem1.level +
                                      "-" +
                                      comparisonItem1.unit +
                                      ")"}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row className="justify-content-between">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem1.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span>
                                      {comparisonItem1.bedroom} Bedroom{" "}
                                      {comparisonItem1.bathroom} Bathroom
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-secondary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setbalconyViewVR(true)
                                      // setbalconyViewVRContent(
                                      //   balconyViewContent[
                                      //     comparisonItem1.level
                                      //   ].day
                                      // )
                                      getVRfilename(
                                        comparisonItem1.level,
                                        comparisonItem1.face
                                      )
                                      setbalconyViewVRLevel(
                                        comparisonItem1.level
                                      )
                                      setbalconyViewVRDirection(
                                        comparisonItem1.face
                                      )
                                    }}
                                  >
                                    <span>{"Balcony View"}</span>
                                  </button>
                                </Col>
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-secondary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setComparisonView(true)
                                    }}
                                  >
                                    <span>{"Compare"}</span>
                                  </button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#2F3D57CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3 align-items-center"
                              style={{
                                borderRadius: "20px",
                                // marginTop: 20,
                              }}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-center">
                                      {"Select Unit On The Building"}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              <CardImg
                                src={iconTap}
                                style={{
                                  // height: "295px",
                                  // height: "100vh",
                                  maxHeight: 50,
                                  objectFit: "contain",
                                }}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col className="col-auto">
                        {comparisonItem2 ? (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#2F3D57CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="my-0 text-primary font-size-20 ">
                                    {"Type " +
                                      comparisonItem2.type +
                                      " (" +
                                      comparisonItem2.block +
                                      "-" +
                                      comparisonItem2.level +
                                      "-" +
                                      comparisonItem2.unit +
                                      ")"}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row className="justify-content-between">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem2.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span>
                                      {comparisonItem2.bedroom} Bedroom{" "}
                                      {comparisonItem2.bathroom} Bathroom
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-secondary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setbalconyViewVR(true)
                                      // setbalconyViewVRContent(
                                      //   balconyViewContent[
                                      //     comparisonItem2.level
                                      //   ].day
                                      // )
                                      getVRfilename(
                                        comparisonItem2.level,
                                        comparisonItem2.face
                                      )
                                      setbalconyViewVRLevel(
                                        comparisonItem2.level
                                      )
                                      setbalconyViewVRDirection(
                                        comparisonItem2.face
                                      )
                                    }}
                                  >
                                    <span>{"Balcony View"}</span>
                                  </button>
                                </Col>
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-secondary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setComparisonView(true)
                                    }}
                                  >
                                    <span>{"Compare"}</span>
                                  </button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#2F3D57CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3 align-items-center"
                              style={{
                                borderRadius: "20px",
                                // marginTop: 20,
                              }}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-center">
                                      {"Select Unit On The Building"}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              <CardImg
                                src={iconTap}
                                style={{
                                  // height: "295px",
                                  // height: "100vh",
                                  maxHeight: 50,
                                  objectFit: "contain",
                                }}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              )}
              {balconyViewVR && (
                <div
                  className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
                  style={{
                    maxHeight: "100vh",
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                  }}
                >
                  <iframe
                    width="560"
                    height="315"
                    src={balconyViewVRContent}
                    id="balconyViewIframe"
                  ></iframe>
                </div>
                // <ReactPhotoSphereViewer
                //   src={balconyViewVRContent}
                //   height={"100vh"}
                //   width={"100%"}
                //   maxFov={90}
                //   minFov={50}
                //   defaultYaw={getDirectionDegree(balconyViewVRDirection)}
                //   defaultZoomLvl={0}
                //   navbar={false}
                //   ref={bvRef}
                //   // panoData={(image, xmpData) => ({
                //   //   croppedWidth: image.width / 2,
                //   //   croppedX: (image.width / 8) * getDirectionCrop(balconyViewVRDirection),
                //   // })}
                // ></ReactPhotoSphereViewer>
              )}
              {balconyViewVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                    // right: 32,
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  }}
                >
                  <Col>
                    <span className="text-primary font-size-56 font-weight-bold ">
                      {"Level " + balconyViewVRLevel}
                    </span>
                  </Col>
                </Row>
              )}
              {balconyViewVR && (
                <Card
                  style={{
                    backgroundColor: "#2F3D57CC",
                    width: "125px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "34px",
                    position: "fixed",
                    bottom: 0,
                    left: 32 + leftSideBarSize,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row className="justify-content-around py-2">
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                          (dayNight ? " navigation-option mm-active" : "")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(true)
                          getVRfilename(
                            balconyViewVRLevel,
                            balconyViewVRDirection
                          )
                          // bvRef.current.setPanorama(
                          //   balconyViewContent[balconyViewVRLevel].day,
                          //   {
                          //     showLoader: false,
                          //   }
                          // )
                        }}
                      >
                        <i className="ph ph-sun"></i>
                      </button>
                    </Col>
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                          (dayNight ? "" : " navigation-option mm-active")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(false)
                          getVRfilename(
                            balconyViewVRLevel,
                            balconyViewVRDirection
                          )
                          // bvRef.current.setPanorama(
                          //   balconyViewContent[balconyViewVRLevel].night,
                          //   {
                          //     showLoader: false,
                          //   }
                          // )
                        }}
                      >
                        <i className="ph ph-moon"></i>
                      </button>
                    </Col>
                  </Row>
                </Card>
              )}
              {balconyViewVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none px-3"
                  }
                  style={{
                    backgroundColor: "#2F3D57CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    left: 32 + leftSideBarSize + 125 + 16,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setbalconyViewVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {full360ViewVR && (
                <ReactPhotoSphereViewer
                  ref={pSRef}
                  src={full360ViewVRContent}
                  height={"100vh"}
                  width={"100%"}
                  maxFov={90}
                  minFov={50}
                  defaultYaw={"90deg"}
                  defaultZoomLvl={0}
                  // sphereCorrection={{pan:Math.PI/2, tilt: 0, roll:0}}
                  // hideNavbarButton={true}
                  // moveSpeed={0}
                  plugins={plugins}
                  navbar={false}
                  // mousemove={false}
                ></ReactPhotoSphereViewer>
              )}
              {full360ViewVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                    // right: 32,
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  }}
                  className="align-items-center"
                >
                  <Col>
                    <span
                      className="text-primary font-size-56 font-weight-bold "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Level " + full360ViewVRLevel}
                    </span>
                  </Col>
                </Row>
              )}
              {full360ViewVR && (
                <Card
                  style={{
                    backgroundColor: "#2F3D57CC",
                    width: "125px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "34px",
                    position: "fixed",
                    bottom: 0,
                    left: 32 + leftSideBarSize + 128 + 16,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row className="justify-content-around py-2">
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                          (dayNight ? " navigation-option mm-active" : "")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(true)
                          pSRef.current.setPanorama(
                            full360ViewContent[full360ViewVRLevel].day,
                            {
                              showLoader: false,
                            }
                          )
                        }}
                      >
                        <i className="ph ph-sun"></i>
                      </button>
                    </Col>
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                          (dayNight ? "" : " navigation-option mm-active")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(false)
                          pSRef.current.setPanorama(
                            full360ViewContent[full360ViewVRLevel].night,
                            {
                              showLoader: false,
                            }
                          )
                        }}
                      >
                        <i className="ph ph-moon"></i>
                      </button>
                    </Col>
                  </Row>
                </Card>
              )}
              {full360ViewVR && (
                <Card
                  style={{
                    backgroundColor: "#2F3D57CC",
                    width: "128px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "20px",
                    position: "fixed",
                    bottom: 0,
                    left: 32 + leftSideBarSize,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row>
                    <Col>
                      {full360Step.map((item, index) => (
                        <Row className="my-2">
                          <button
                            key={"level-360-" + index}
                            className={
                              full360ViewVRLevel == item
                                ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                                : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              pSRef.current.setPanorama(
                                dayNight
                                  ? full360ViewContent[item].day
                                  : full360ViewContent[item].night,
                                {
                                  showLoader: false,
                                }
                              )
                              setfull360ViewVRLevel(item)
                            }}
                          >
                            <span>{"Level " + item}</span>
                          </button>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Card>
              )}
              {full360ViewVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none px-3"
                  }
                  style={{
                    backgroundColor: "#2F3D57CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    left: 32 + leftSideBarSize + 128 + 16 + 125 + 16,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setfull360ViewVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {comparisonView && !full360ViewVR && (
                <CardBody
                  className="p-0"
                  style={{
                    height: "100vh",
                  }}
                >
                  <Row
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      paddingTop: 16,
                      paddingLeft: 32,
                      paddingRight: 32,
                      // right: 32,
                    }}
                    className="align-items-center"
                  >
                    <Col>
                      <span className="text-primary font-size-56 font-weight-bold ">
                        {"UNIT TYPE"}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "100px" }}>
                    <Col>
                      <Row className="justify-content-center">
                        <Col className="col-4">
                          <Card
                            className="m-3"
                            style={{
                              background: "#2F3D57CC",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                                minHeight: "80vh",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="mt-0 text-primary font-size-24 ">
                                    {comparisonItem1 &&
                                      "Type " + comparisonItem1.type}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  position: "absolute",
                                  bottom: 16,
                                  left: 0,
                                  right: 0,
                                }}
                                className="justify-content-center"
                              >
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenFloor1}
                                    toggle={() => {
                                      setDropdownOpenFloor1(!dropdownOpenFloor1)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3 bg-transparent"
                                      }
                                    >
                                      {selectedFloor1
                                        ? "Level " + selectedFloor1
                                        : "Select Floor"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {floorDropDown.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setUnitDropdown1(UnitPoint[index])
                                            setSelectedFloor1(item)
                                          }}
                                        >
                                          {"Level " + item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenUnit1}
                                    toggle={() => {
                                      setDropdownOpenUnit1(!dropdownOpenUnit1)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3 bg-transparent"
                                      }
                                    >
                                      {comparisonItem1
                                        ? comparisonItem1.block +
                                          "-" +
                                          comparisonItem1.level +
                                          "-" +
                                          comparisonItem1.unit
                                        : "Select Unit"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {unitDropdown1.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setComparisonItem1(item)
                                            setSelectedFloor1(item.level)
                                          }}
                                        >
                                          {item.block +
                                            "-" +
                                            item.level +
                                            "-" +
                                            item.unit}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem1 && comparisonItem1.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    {comparisonItem1 && (
                                      <span>
                                        {comparisonItem1.bedroom} Bedroom{" "}
                                        {comparisonItem1.bathroom} Bathroom
                                      </span>
                                    )}
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              {comparisonItem1 && (
                                <CardImg
                                  src={getFloorPlan(comparisonItem1.type)}
                                  style={{
                                    // height: "295px",
                                    // height: "100vh",
                                    maxHeight: "63vh",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-4">
                          <Card
                            className="m-3"
                            style={{
                              background: "#2F3D57CC",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                                minHeight: "80vh",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="mt-0 text-primary font-size-24">
                                    {comparisonItem2 &&
                                      "Type " + comparisonItem2.type}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  position: "absolute",
                                  bottom: 16,
                                  left: 0,
                                  right: 0,
                                }}
                                className="justify-content-center"
                              >
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenFloor2}
                                    toggle={() => {
                                      setDropdownOpenFloor2(!dropdownOpenFloor2)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3 bg-transparent"
                                      }
                                    >
                                      {selectedFloor2
                                        ? "Level " + selectedFloor2
                                        : "Select Floor"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {floorDropDown.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setUnitDropdown2(UnitPoint[index])
                                            setSelectedFloor2(item)
                                          }}
                                        >
                                          {"Level " + item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenUnit2}
                                    toggle={() => {
                                      setDropdownOpenUnit2(!dropdownOpenUnit2)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3 bg-transparent"
                                      }
                                    >
                                      {comparisonItem2
                                        ? comparisonItem2.block +
                                          "-" +
                                          comparisonItem2.level +
                                          "-" +
                                          comparisonItem2.unit
                                        : "Select Unit"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {unitDropdown2.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setComparisonItem2(item)
                                            setSelectedFloor2(item.level)
                                          }}
                                        >
                                          {item.block +
                                            "-" +
                                            item.level +
                                            "-" +
                                            item.unit}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem2 && comparisonItem2.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    {comparisonItem2 && (
                                      <span>
                                        {comparisonItem2.bedroom} Bedroom{" "}
                                        {comparisonItem2.bathroom} Bathroom
                                      </span>
                                    )}
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              {comparisonItem2 && (
                                <CardImg
                                  src={getFloorPlan(comparisonItem2.type)}
                                  style={{
                                    // height: "295px",
                                    // height: "100vh",
                                    maxHeight: "63vh",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Card
                    style={{
                      backgroundColor: "#2F3D57CC",
                      width: "168px",
                      backdropFilter: "blur(20px)",
                      borderRadius: "20px",
                      position: "fixed",
                      bottom: 0,
                      left: 32 + leftSideBarSize,
                    }}
                    className="px-4 py-0 mb-3"
                  >
                    <Row>
                      <Col>
                        <Row className="my-2">
                          <button
                            className={
                              "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setComparisonView(false)
                            }}
                          >
                            <span>{"Overview"}</span>
                          </button>
                        </Row>
                        <Row className="my-2">
                          <button
                            className={
                              "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setComparisonView(true)
                            }}
                          >
                            <span>{"Comparison"}</span>
                          </button>
                        </Row>
                        <Row className="my-2">
                          <button
                            className={
                              "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setfull360ViewVR(true)
                            }}
                          >
                            <span>{"Full 360 View"}</span>
                          </button>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Unit)
)

Unit.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
